.show_dlt {
    position: relative;;
}

.hide_dlt {
max-width: 0;
overflow: hidden;
position: absolute;
}

.closing_progress{
    z-index:1050;
    top:33%;
    left:40px;
    right:40px;
    height: 200px;
    /* background:white !important;
    box-shadow: 0px 2px 6px 2px rgb(0 0 0 / 30%); */
    border-radius: 5px;
}

.table_row_dlt:hover .hide_dlt{
display: flex;
justify-content: center;
align-items: center;
position: absolute;
left: 0;
max-width: 1000px;
top: 0;
min-width: 100%;
height: 100%;
padding: 2px !important;
transition: max-width 0.2s ease-in;
background-color: gray;
font-size: 16px !important;
cursor: pointer;
}
